/* Bootstrap 3 overrides */
/* Horizontal allignment */
.hcenter {
  text-align: center;
}

.hright {
  text-align: right;
}

.hleft {
  text-align: left;
}

.bold {
  font-weight: 700;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Avenir LT W01_45 Book1475508";
  display: table;
  width: 100%;
}

html > body {
  height: 100%;
}

#main-container, #bottom-container {
  display: table-row;
  height: 1px;
  overflow: hidden;
}

#main-container {
  height: auto;
}

#error-page {
  /* Small Devices, Tablets */
  /* Medium Devices, Desktops */
  /* Large Devices, Wide Screens */
}
#error-page #logo {
  padding-top: 25px;
  padding-bottom: 25px;
}
#error-page #logo a {
  display: block;
}
#error-page #logo a img {
  height: 80px;
}
@media only screen and (min-width: 768px) {
  #error-page #logo a img {
    height: 100px;
  }
  #error-page #top-menu {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 992px) {
  #error-page #logo a img {
    height: 130px;
  }
  #error-page #top-menu {
    margin-top: 65px;
  }
}
@media only screen and (min-width: 1200px) {
  #error-page #logo a img {
    height: 150px;
  }
  #error-page #top-menu {
    margin-top: 75px;
  }
}

#global-topbar {
  height: 35px;
  background-image: none;
  background-color: #939393;
}
#global-topbar #contact-info {
  height: 35px;
  line-height: 35px;
  color: #FFFFFF;
  font-weight: 700;
}
#global-topbar #language-switcher {
  padding-right: 25px;
  text-transform: uppercase;
  font-weight: 700;
}
@media only screen and (max-width: 768px) {
  #global-topbar #language-switcher {
    float: none !important;
  }
}
#global-topbar #language-switcher ul {
  height: 35px;
  line-height: 35px;
}
#global-topbar #language-switcher ul li a:active, #global-topbar #language-switcher ul li a:link, #global-topbar #language-switcher ul li a:visited {
  color: #FFFFFF;
}
#global-topbar #language-switcher ul li a:hover, #global-topbar #language-switcher ul li a.language_item_active {
  color: #053e84;
  text-decoration: none;
}
#global-topbar .btn-mybbf, #global-topbar .btn-bbf-be {
  height: 35px;
  font-weight: 700;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
#global-topbar .btn-mybbf {
  background-color: #FFFFFF;
  color: #053e84;
  margin-left: 20px;
}
#global-topbar .btn-mybbf-dropdown {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
#global-topbar .btn-bbf-be {
  background-color: #ff6600;
  color: #FFFFFF;
}
#global-topbar a.brand:hover, #global-topbar a.brand:active, #global-topbar a.brand:link, #global-topbar a.brand:visited {
  text-decoration: none;
}
#global-topbar a.brand.bbf-be {
  background-color: #ff6600;
  color: #FFFFFF;
}
#global-topbar a.brand.bbf-mybbf {
  background-color: #FFFFFF;
  color: #053e84;
  margin-left: 20px;
}

/* Bootstrap 3 overrides */
/* Horizontal allignment */
.hcenter {
  text-align: center;
}

.hright {
  text-align: right;
}

.hleft {
  text-align: left;
}

.bold {
  font-weight: 700;
}

#global-member-of {
  background-color: #dde6f3;
  padding-top: 20px;
  padding-bottom: 20px;
}
#global-member-of .header {
  font-size: 2em;
  font-weight: 200;
  color: #053e84;
  text-align: center;
}
#global-member-of .banners {
  text-align: center;
}
#global-member-of .banners a.banner {
  padding: 10px;
  outline-style: none;
}
#global-member-of .banners a.banner:hover {
  text-decoration: none;
}
#global-member-of .banners a.banner img {
  width: 150px;
  /* Small Devices, Tablets */
  /* Medium Devices, Desktops */
  /* Large Devices, Wide Screens */
}

/* Bootstrap 3 overrides */
/* Horizontal allignment */
.hcenter {
  text-align: center;
}

.hright {
  text-align: right;
}

.hleft {
  text-align: left;
}

.bold {
  font-weight: 700;
}

#global-footer {
  background-color: #939393;
  padding-top: 10px;
  padding-bottom: 10px;
}
#global-footer .stay-tuned .header {
  font-size: 2em;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
}
#global-footer .stay-tuned .social-media {
  text-align: center;
}
#global-footer .stay-tuned .social-media a img {
  width: 30px;
}
#global-footer .stay-tuned .panel .panel-body {
  background-color: #dde6f3;
}
#global-footer .address, #global-footer .copyright {
  text-align: center;
  color: #FFFFFF;
}

#login-page #validation-error .help-block, #lost-password-page #validation-error .help-block {
  margin: 0;
  color: inherit;
  display: inherit;
}
#login-page #lost-password, #lost-password-page #lost-password {
  text-align: right;
}
#login-page #lost-password > a:link, #login-page #lost-password > a:visited, #login-page #lost-password > a:active, #lost-password-page #lost-password > a:link, #lost-password-page #lost-password > a:visited, #lost-password-page #lost-password > a:active {
  color: #939393;
}
#login-page #lost-password > a:hover, #lost-password-page #lost-password > a:hover {
  color: #053e84;
  text-decoration: none;
}
#login-page .go-back, #lost-password-page .go-back {
  text-align: center;
}
#login-page .go-back a:link, #login-page .go-back a:visited, #login-page .go-back a:active, #lost-password-page .go-back a:link, #lost-password-page .go-back a:visited, #lost-password-page .go-back a:active {
  color: #939393;
}
#login-page .go-back a:hover, #lost-password-page .go-back a:hover {
  color: #053e84;
  text-decoration: none;
}
#login-page .remember-me label, #lost-password-page .remember-me label {
  color: #939393;
  font-weight: 200;
}
#login-page #logo.img-responsive, #lost-password-page #logo.img-responsive {
  margin-top: 20px;
  margin-bottom: 20px;
}

#lost-password-page #user-password-rest-form label.error {
  margin-top: 15px;
  color: #FF0000;
}

#landing-page #landing-page-content {
  margin-top: 20px;
}
